import React, {useState, useEffect} from 'react'
import Container from 'gatsby-theme-amsterdam/src/components/Container'
import SEO from 'gatsby-theme-amsterdam/src/components/SEO'
import styled from '@emotion/styled'
import ReactDOMServer from 'react-dom/server';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"

const Title = styled.h1`
  font-weight: ${props => props.theme.fonts.boldWeight};
  line-height: 1.25;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: 0 auto 1rem;
  font-size: 2rem;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    font-size: 2.5rem;
  }
`

const Content = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  p {
    line-height: 1.5;
    margin: 0 0 1.75rem;
  }
  a {
    transition: 0.3s color;
    color: ${props => props.theme.colors.secondary};
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    @media (hover: none) {
      color: ${props => props.theme.colors.secondary} !important;
    } 
  }
  code {
    font-family: ${props => props.theme.fonts.monospace};
    font-size: 0.9rem;
    padding: 0.25rem;
    background: ${props => props.theme.colors.code};
    color: ${props => props.theme.colors.text};
    border-radius: 0.3em;
  }
  input {
    border: 2px solid black;
    background-color: white;
    border-radius: 4px;
  }
  th {
    vertical-align: top;
    text-align: left;
  }
  span.bold-red {
    color: red;
    font-weight: bold;
  }
  img.thumbnail {
    height: 200px;
    width: 200px;
  }
  
`
var ps = { lat: 39.133106, lng: -77.244895 }
var re = [], setRe

/*
const FetchHouseData = (props) => 
{
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    console.log("in this functions",props.testing)
  
    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    let state = "MD"
    let city = "Gaithersburg"
    let limit = 200
    let offset = 0
    useEffect(() => {
      fetch(`https://realtor.p.rapidapi.com/properties/v2/list-for-sale?sort=relevance&city=${city}&limit=${limit}&offset=${offset}&state_code=${state}`, 
            {
                "method": "GET",
                "headers": 
                {
                    "x-rapidapi-host": "realtor.p.rapidapi.com",
                    "x-rapidapi-key": "adb418351cmsh88fe2aa1172e1b8p1c99a2jsnc0be8143e571"
                }
            }
        )
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result.properties);
            console.log("printing Result:")
            console.log(result.properties);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }, [])
  
    if (error) 
    {
      return <div>Error: {error.message}</div>;
      //return error.message
    }
    else if (!isLoaded) 
    {
      return <div>Loading...</div>;
      //return "loading"
    } 
    else 
    {
      console.log(items)
      //return ps
      return <>
      <div>Success!!</div>;
      
      
      
      </>
      /*return (
        <ul>
          {items.map(item => (
            <li key={item.property_id}>
              Price: {item.price} URL: {' '} <a href={item.rdc_web_url} target="_blank" rel="noopener noreferrer"> 
                                                House
                                            </a>{' '}
            </li>
          ))}
        </ul>
      );
    }
}
*/
/*
const FetchCoord = (props) => 
{
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    let zipCode = props.zip
    useEffect(() => {
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBZtYPvVfmPHqF_Q5HDMmaqfOdlBDNrEQs&components=postal_code:${zipCode}`)
      .then(res => res.json())  
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.results[0].geometry.bounds.northeast);
          //console.log(`Geocoding for ${zipCode} returns:`,result.results[0].geometry.bounds.northeast);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
    }, [props.zip])

    if (error) 
    { return error.message }

    else if (!isLoaded) 
    { return "loading" } 
    
    else 
    {
      let arr = [items.lat, items.lng]
      re = {...arr} 
      console.log("somehting",arr,"ree", re)
      return arr;
    }
}
let erroring = null,isLoaded = false,items=[] 
    let zipCode = props.zip
*/

/*
var list = 
[
  { lat: 39.133106, lng: -77.244895 },
  { lat: 39.130106, lng: -77.244895 },
  { lat: 39.133106, lng: -77.244095 }

]
*/
const MyMapComponent = withScriptjs(withGoogleMap((props) =>
  {
    let test = 0
    const [selectedHouse, setSelectedHouse] = useState(null);

    return (
    <GoogleMap
      zoom = {parseInt(props.zoom)}
      //defaultCenter={{ lat: 39.133106, lng: -77.244895 }}
      center = {props.cord}
    >

      {//props.isMarkerShown && <Marker position={props.cord} />
      }
      {props.houses.map(points => (
        <Marker
          key={points.property_id}
          position={{
            lat : points.address.lat,
            lng : points.address.lon
          }}
          onClick = { () => {
            setSelectedHouse(points);
          }}
        />
        ))}

        {selectedHouse && (
          <InfoWindow
            position={{
              lat : selectedHouse.address.lat,
              lng : selectedHouse.address.lon
            }}
            onCloseClick = { () => {
              setSelectedHouse(null);
            }}
          >
          <div>
            <a href={selectedHouse.rdc_web_url} target="_blank" rel="noopener noreferrer"><img class="thumbnail" src={selectedHouse.thumbnail} alt="House Image" /></a>
            <table border="0">
              <tr>
                <th>Price:</th> 
                <td>{selectedHouse.price}</td>
              </tr>
              <tr>
                <th>Beds:</th> 
                <td>{selectedHouse.beds}</td>
              </tr>
              <tr>
                <th>Baths:</th> 
                <td>{selectedHouse.baths}</td>
              </tr>
              {/*<tr>
                <th>Size:</th> 
                <td>{ () => { 
                      if (selectedHouse.building_size)
                        return ''.concat(selectedHouse.building_size.size," ",selectedHouse.building_size.units) 
                      else 
                        return "No info"
                    }}
                </td>
              </tr>
                  */}
              <tr>
                <th>Link:</th> 
                <td><a href={selectedHouse.rdc_web_url} target="_blank" rel="noopener noreferrer">Realtor.com</a></td>
              </tr>
            </table>
          </div>
          </InfoWindow>
          
        )}
    </GoogleMap>
    )
  }

  ))

const FetchHouseData = async(props) => 
{
    //console.log("in this functions",props.testing)
  
    let state = props.state ?? "MD"
    let city = props.city ?? "Gaithersburg"
    let limit = 200
    let offset = 0
   
    return fetch(`https://realtor.p.rapidapi.com/properties/v2/list-for-sale?sort=relevance&city=${city}&limit=${limit}&offset=${offset}&state_code=${state}`, 
        {
            "method": "GET",
            "headers": 
            {
                "x-rapidapi-host": "realtor.p.rapidapi.com",
                "x-rapidapi-key": "adb418351cmsh88fe2aa1172e1b8p1c99a2jsnc0be8143e571"
            }
        }
      )
      .then(res => res.json())
      .then(
        (result) => 
        {
          //console.log("printing Result:")
          //console.log(result.properties);
          return result.properties;
        },
        (error) => 
        {
          return error.message
        }
      )
}

const FetchCoord = async(props) =>
{

  let addr = props.loc ?? "Gaithersburg,Maryland"
  return fetch(`https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBZtYPvVfmPHqF_Q5HDMmaqfOdlBDNrEQs&address=${addr}`)
        .then(res => res.json())
        .then(
          (result) => 
          {
            let items = result.results[0].geometry.location;
            let arr = [items.lat, items.lng];
            return arr;
          },
          (error) => 
          {
            return error.message;
          }
        )
}


const ExamplePage = ({ data }) => 
{

  const [zooming, setZ] = useState(12);
  const [postal, setPostal] = useState("20878");
  const [points, setP] = useState([]);

  const [cityForm,setCityForm] = useState("Field Required");
  const [stateForm,setStateForm] = useState("Field Required");
  const [disableSubmit,setSubmit] = useState(true);
  const [loading,setLoading] = useState("");

  const [houses,setHouses] = useState([]);

  const handleSubmit = async (evt) => 
  {
    evt.preventDefault();
    const form = event.target;
    const data = new FormData(form);

    let cityF = data.get("city")
    let stateF = data.get("state")
    //let zoom = data.get("zoom")
    let zip_codeF = data.get("zipC")
    //setPostal(zip_code)
    //setZ(zoom)

    let location  = ''.concat(cityF,",",stateF,",",zip_codeF)
    console.log(location)
    setLoading("Loading House Data...")
    FetchHouseData({city:cityF,state:stateF}).then(res => {
      
      setHouses(res)
      /*res.forEach(points => 
      {
        let key = points.property_id
        console.log("key",key)
        let lat = points.address.lat
        let lng = points.address.lon
        console.log("lat",lat,"lng",lng)
      })*/
      console.log("house",res)
      setLoading("")
    })

    FetchCoord({loc:location}).then(res2 => {
      setP(res2) 
    })


    console.log( "event", points )
  }

  const cityRequired = (evt) =>
  {
    evt.preventDefault();
    //console.log("in req",evt.target.value)
    let val = evt.target.value

    if (val == '')
    { setCityForm("Field Required"); }
    else
    { setCityForm(""); }

    
    if ( (val != '') && (stateForm == "") )
    { setSubmit(false); }
    else 
    { setSubmit(true); }
  }

  const stateRequired = (evt) =>
  {
    evt.preventDefault();
    //console.log("in req",evt.target.value)
    let val = evt.target.value

    if (val == '')
    { setStateForm("Field Required"); }
    else
    { setStateForm(""); }


    if ( (cityForm == "") && (val != '') )
    { setSubmit(false); }
    else 
    { setSubmit(true); }
  }
    return (
        <Container>
            <SEO title="Map Page" description="This is just an example page" />
            <Title>Map Page</Title>
            <Content>
              <p>
                <MyMapComponent 
                  cord = {{ lat: points[0], lng: points[1] }} 
                  zoom = {10}
                  houses= {houses}
                  isMarkerShown
                  googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBZtYPvVfmPHqF_Q5HDMmaqfOdlBDNrEQs"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
                <h3>This is an example of using Google Maps.</h3>
                <br/>
                Points: {''.concat ("lat:",points[0],", long:", points[1] )}
                <br/>
                <form onSubmit={handleSubmit}>
                  <p>
                    <table border="0">
                      {/*<tr>
                        <td>Zooming test:</td> 
                        {//<td><input type='number' onChange={e => setZ(e.target.value)} /> </td>
                        }
                        <td><input type='number' name='zoom' /></td>
                      </tr>
                      */}
                      <tr>
                        <th> City: </th>
                        <td><input type='text' name='city'  onChange={cityRequired}/> <span class="bold-red">{cityForm}</span></td>
                      </tr>
                      <tr>
                        <th> State Abrev: </th>
                        <td><input type='text' name='state' onChange={stateRequired}/> <span class="bold-red">{stateForm}</span></td>
                      </tr>
                      <tr>
                        <th> Zip Code: </th>
                        <td><input type='text' name='zipC'/> </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td><input type="submit" value="Search" disabled={disableSubmit} /> {loading}</td>
                      </tr>
                    </table>
                  </p>
                  {/*<input type="button" value="stuff" onClick={e => setZ(8) }/>*/}
                </form> 

                {/*
                < FetchHouseData 
                  testing = "whaaaa"
                />
                */}
              </p>

            </Content>
        </Container>
    )
}

export default ExamplePage
